import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialIcon from 'GLOBAL/MaterialIcon';

import { QuestionnaireContext } from './context';

const Header = (props) => {

    const _c = useContext(QuestionnaireContext);

    return (
        <div className="irjs__form--heading">
			<h1> {(_c.context.param?.customText && _c.context.param?.customText.questionnaireHeading) || _c.lang.getSlugTranslation({slug:'questionnaire.heading'})} </h1> 
			<button 
				type="button"
				className = {clsx('irjs__btn', 'irjs__btn--close')}
				title="Close"
				onClick={(e) => {
                        _c.analytics.track('guidedrec modal closed', { });
                        if (props.onClose && props.onClose.constructor === Function) props.onClose(e);
                    }
                }>
                <MaterialIcon icon="close" />
            </button>
        </div>
    );
};

export default React.memo(Header);