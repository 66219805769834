import React, { createRef, useContext, useEffect, useState, } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import InputSelectorGroup from 'COMPONENTS/Input/InputSelectorGroup';
import { localePrice } from 'HELPERS/price';
import Emitter from 'UTILS/Emitter';
import usePrevious from 'HOOKS/usePrevious';
import useMountedRef from 'HOOKS/useMountedRef';

import { QuestionnaireContext } from './context';
import PriceRange from './PriceRange';

const CoreQuestion = React.forwardRef(function QuestionPopup(props, ref) {

  const { isActive, ...prop } = props;
  const {questions, activeIndex, ...irContext} = useContext(QuestionnaireContext);
  const currentQuestion = questions[activeIndex];

  const [multiChoices, setMultiChoices] = useState(props.choices || []);
  const [questionValues, setQuestionValues] = useState(props.values);
  const prevValues = usePrevious(questionValues);
  const mounted = useMountedRef();

  const setQuestionChoices = ({ key, value, fieldValue }) => {

	  const tmpValues = (!questionValues) ? [] : questionValues;
    const choiceId = Number(fieldValue);
    const values = [...tmpValues];
    let question_selection = [];
    

    if(prop.maximum_selected === 1){ // only one
      if(value) question_selection = [...new Set([choiceId])];
      if(!value) question_selection = [];
    }

    if(!prop.maximum_selected || prop.maximum_selected > 1){
      if (value) {
        const newValueSet = [...tmpValues, choiceId].sort((a, b) => a - b);
        question_selection = [...new Set(newValueSet)];
      }
      if (!value) {
        question_selection = values.filter((choice) => choice !== choiceId);
      }
    }

    if(props.onChange.constructor === Function) props.onChange(props.id, question_selection);
    setQuestionValues(question_selection);
  };

  const isSelected = (choice) => {
    let selections = false;
    const { id, requires_choice_ids } = choice;
    const { qValues } = irContext;

    if (typeof prop.values === 'array' && prop.values.length) selections = prop.values;

    if (!selections && Object.keys(irContext._val.current).includes(String(prop.id)) && irContext._val.current[prop.id].length) {
      selections = irContext._val.current[prop.id];
    }

    if(!choice.requires_choice_ids.length) return (selections && selections.length) ? Boolean(selections.find((choiceId) => Number(choiceId) === id)) : false;
    return (hasRequiredChoices(choice) && selections && selections.length) ? Boolean(selections.find((choiceId) => Number(choiceId) === id)) : false;
  };

  const hasRequiredChoices = (choice) => {
    const { requires_choice_ids, id } = choice;

    if(!requires_choice_ids.length) return true;

    const allChoices = Object.values(irContext._val.current)
                    .filter(key => Array.isArray(key) && key.length)
                    .flat();

    return ((allChoices.length && requires_choice_ids.length && requires_choice_ids.some((val) => allChoices.includes(Number(val)))) || false);
  };

  const sortSelectableChoices = (choices) => {

      const choicesHasRequired = choices.some((choice) => choice.requires_choice_ids.length > 0);

      if(!choicesHasRequired){
        return choices;
      }
      if(choicesHasRequired){
        return choices.sort((a, b) => {
          const allChoices = Object.values(irContext._val.current)
                      .filter(key => Array.isArray(key) && key.length)
                      .flat();

          const aHas = a.requires_choice_ids.some((_c) => allChoices.includes(Number(_c)));
          const bHas = b.requires_choice_ids.some((_c) => allChoices.includes(Number(_c))); 

          if(aHas && !bHas) {
            return -1;
          } else if(aHas && !bHas) {
            return 1;
          } else {
            return 0;
          }
        });
      }
  }

   const checkChoiceValidation = (choice) => {
    if (!choice.requires_choice_ids.length) return true;
    if (choice.requires_choice_ids.length && hasRequiredChoices(choice)) return true;
    return false;
  };

  const handleValidationError = () => {
    /// alert('Requires a choice to be selected.');
  };

  const disableNext = () => {
    const condition = Boolean(
      activeIndex < Object.keys(irContext.questionnaire._order).length - 1
        && currentQuestion
        && Object.keys(irContext._val.current).includes(String(currentQuestion.id))
        && irContext._val.current[currentQuestion.id].length >= currentQuestion.minimum_selected
        && (currentQuestion.maximum_selected === null
          || (currentQuestion.maximum_selected !== null
            && irContext._val.current[currentQuestion.id].length <= currentQuestion.maximum_selected))
    );

    return condition;
  };

  useEffect(() => {

      const _nextBtn = document.querySelector('button.irjs__btn-footer--next');

      const handleInputChange = (e) => {
          _nextBtn.classList.toggle('irjs__btn-footer--animate', (e.target.checked));
      };

      if(document.querySelector('.irjs__question')){

          var observer = new MutationObserver((mutationsList, observer) => {
              for (var mutation of mutationsList) {
                  if (mutation.type === 'childList') {
                      mutation.addedNodes.forEach((addedNode) => {
                          if (addedNode.classList) {
                              if(addedNode.classList.contains('irjs__selector') && addedNode.getAttribute('data-typeofchoice') === 'multiple'){
                                  addedNode.querySelectorAll('input').forEach((inputElement) => {
                                      inputElement.addEventListener('change', handleInputChange);
                                  });
                              }
                          }
                      });
                      mutation.removedNodes.forEach((removedNode) => {
                      });
                  }
              }
          });

          observer.observe(document.querySelector('.irjs__question'), { attributes: true, childList: true, subtree: true });

          document.querySelectorAll('.irjs__selector[data-typeofchoice="multiple"] input').forEach((element) => {
              element.addEventListener('change', handleInputChange);
          });

          _nextBtn.addEventListener('animationend', () => { _nextBtn.classList.remove('irjs__btn-footer--animate'); });


      }

      return () => {
        document.querySelectorAll('.irjs__selector[data-typeofchoice="multiple"] input').forEach((element) => {
          element.removeEventListener('change', handleInputChange);
        });
      }



    }, [irContext.activeIndex]);


  useEffect(() => {
    if (prop.type === 'multiple_choice') {
      if(prevValues && questionValues && prevValues.length !== questionValues.length) {
        const tmpValues = questionValues;
        const filteredQuestions = questions.filter((_ques) => (
            _ques.type === 'multiple_choice' 
            && _ques.id !== props.id 
            && irContext._val.current[_ques.id] 
            && irContext._val.current[_ques.id].length
          ));

        const _questionChoiceIds = props.choices.reduce((arr, choice, idx) => {
          return [...new Set([...arr, choice.id])];
        }, []);

        if(filteredQuestions.length) {
          filteredQuestions.forEach((_question) => {
            const choicesWithRequied = _question.choices.filter((_choice) => _choice.requires_choice_ids.length);
            const questionRequiredIds = choicesWithRequied.reduce((arr, choice, idx) => {
              const { requires_choice_ids } = choice;
              if (requires_choice_ids.length) {
                arr = [...new Set([...arr, ...requires_choice_ids])];
              }
              return arr;
            }, []);

            if(questionRequiredIds.some((val) => _questionChoiceIds.includes(val))){
              if (!questionRequiredIds.some((val) => tmpValues.includes(Number(val)))) {
                props.onValueChange(_question.id, []);
              }
            }
          });
        }
      }
    }

    if(prop.type === 'price_range' || prop.type === 'ns_slider'){
      if(Array.isArray(questionValues)) setQuestionValues({ min: prop.default_range_min || 0, max: prop.default_range_max || 500 });
    }

    setTimeout(() => {
		  Emitter.emit('choicesUpdated');
    }, 500);


  }, [questionValues, prevValues]);

  useEffect(() => {
    setQuestionValues(prop.values);
  }, [prop.values]);

  useEffect(() => {
    setMultiChoices(sortSelectableChoices(props.choices));
  }, [prop.choices, activeIndex]);

  return (
    <>


      <div className="irjs__question">
        <div className={ clsx('irjs__question--heading', irContext.context.getCustomStyling('questionnaire.heading')) }>
            <div>
              <h1>{prop.text}</h1>
              {prop.type === 'multiple_choice' && (
                <span>
                <em>{irContext.lang.getSlugTranslation({slug:`questionnaire.${prop.minimum_selected === 1 ? 'required' : 'optional'}` , canTitleize: false})}</em>
                </span>
              )}

              {prop.type === 'multiple_choice' && (
                <span className={clsx('')}>
                 <em>{irContext.lang.getSlugTranslation({slug:`questionnaire.${(prop.maximum_selected === 1) ? 'selectonlyone' : 'selectonemore'}` , canTitleize: false})}</em>
                </span>
              )}

              {(prop.type === 'price_range' || prop.type === 'ns_slider') && (
                <span className={ clsx('') }>
                  <em>{irContext.lang.getSlugTranslation({slug:`questionnaire.optional`, canTitleize: false})}</em>
                </span>
              )}
            </div>

            {(prop.type === 'multiple_choice' && !prop.maximum_selected) && (
              <div className={clsx('irjs__select-actions')}>
                <button
                  type="button"
                  disabled={ questionValues && questionValues.length === prop.choices.length }
                  className={ clsx('') }
                  onClick={ () => {
                    // deselect
                    const checkboxes = document.querySelectorAll(`input[id^="choice-${prop.id}-"]`);
                    const _choicesAdded = [];
                    checkboxes.forEach((_checkbox) => {
                      if (!_checkbox.checked && !_checkbox.disabled) {
                        _checkbox.checked = true;
                        _choicesAdded.push(Number(_checkbox.value));
                      }
                    });

                    const newValueSet = [...questionValues, ..._choicesAdded].sort((a, b) => a - b);
                    const _newSelection = [...new Set(newValueSet)];
                    setQuestionValues(_newSelection);
                    if(props.onChange.constructor === Function) props.onChange(prop.id, _newSelection);

                    irContext.analytics.track('guided rec select all choices button clicked', {
                      questionnaire_id: irContext.questionnaire.id,
                      question_number: props.number,
                      question_id: props.id,
                      question_group_slug: props.mixpanel_group_slug,
                    });

                  } }
                >
                    {irContext.lang.getSlugTranslation({slug:`guidedrec.button.selectall`, canTitleize: false})}
                </button>
                <button
                  type="button"
                  className={ clsx('') }
                  disabled={ questionValues && questionValues.length === 0 }
                  onClick={ () => {
                    const checkboxes = document.querySelectorAll(`input[id^="choice-${prop.id}-"]:checked`);
                    checkboxes.forEach((_checkbox) => {
                      if (_checkbox.checked && !_checkbox.disabled) {
                        _checkbox.checked = false;
                      }
                    });

                    setQuestionValues([]);
                    if(props.onChange.constructor === Function) props.onChange(prop.id, []);

                    irContext.analytics.track('guided rec clear choices button clicked', {
                      questionnaire_id: irContext.questionnaire.id,
                      question_number: props.number,
                      question_id: props.id,
                      question_slug: props.mixpanel_group_slug,
                    });

                  } }
                >
                    {irContext.lang.getSlugTranslation({slug:`guidedrec.button.clear`, canTitleize: false})}
                </button>
            </div>
          )}
        </div>

        <div className={clsx(
          'irjs__question',
          prop.type === 'multiple_choice' && 'irjs__question--selection'
        )}>
          {prop.type === 'multiple_choice' && (

            <InputSelectorGroup
                    className={ clsx('irjs__selector-group', prop.number === 1 && 'irjs__selector-group-single') }
                    type={prop.maximum_selected === 1 ? 'radio' : 'checkbox'}
                    name={ 'selector-choice' }
                    value={ prop.maximum_selected === 1 ? irContext._val.current[prop.id][0] : null}
                    fields={ prop.choices.map((choice, i) => {
                      return {
                          className: ['irjs__selector--choice', irContext.context.getCustomStyling('questionnaire.selector')],
                          id:  `choice-${prop.id}-${choice.id}-${choice.number}`,
                          value:  choice.id,
                          label:  choice.text,
                          disabled:  choice.requires_choice_ids.length ? !hasRequiredChoices(choice) : false,
                          checked: () => isSelected(choice),
                          onClickValidate: () => checkChoiceValidation(choice),
                          attr: {
                              'data-typeofchoice': ((!prop.minimum_selected || prop.minimum_selected === 1) && prop.maximum_selected === 1) ? 'single' : 'multiple'
                            }
                      };
                    }
                    ) }
                    onClick={ (option) => {
                      setQuestionChoices(option);
                      irContext.analytics.track(`guided rec choice ${option.value ? 'selected' : 'deselected'}`, {
                          questionnaire_id: irContext.questionnaire.id,
                          question_number: prop.number,
                          question_id: prop.id,
                          question_group_slug: prop.mixpanel_group_slug,
                          choice_id: option.fieldValue,
                          choice_selected: option.value,
                          choice_label: option.label,
                        });

                     }}
                  />
          )}

          {(prop.type === 'price_range' || prop.type === 'ns_slider') && ( <PriceRange {...prop} /> )}

        </div>
      </div>

    </>
  );
});

export default CoreQuestion;
