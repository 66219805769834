import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { QuestionnaireContext } from './context';
import Emitter from 'UTILS/Emitter';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';

const Footer = (props) => {
	const { questions, ...irContext } = useContext(QuestionnaireContext);
	const currentQuestion = questions[irContext.activeIndex];
	// minimum_number_of_questions_answered

	const [, updateState] = useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [startOverDisabled, setStartOverDisabled] = useState(true);

	const disableBack = () => Boolean(irContext.activeIndex > 0);
	const disableNext = () => {
    let condition = true;

    if(currentQuestion.type === 'multiple_choice'){
      condition = (Object.keys(irContext._val.current).includes(String(currentQuestion.id))
        && irContext._val.current[currentQuestion.id].length >= currentQuestion.minimum_selected
        && (currentQuestion.maximum_selected === null
          || (currentQuestion.maximum_selected !== null
            && irContext._val.current[currentQuestion.id].length <= currentQuestion.maximum_selected)));
    }

    return Boolean((irContext.activeIndex < questions.length - 1) && condition);
  };

	useEffect(() => {
		 let hasChoices = false;

      if (irContext._val.current) {
          hasChoices = Boolean(Object.values(irContext._val.current)
							  .filter(key => Array.isArray(key) && key.length)
							  .flat().length > 0);

          if (irContext.activeIndex >= 0 && hasChoices) {
              setStartOverDisabled(false);
          }
          if (!hasChoices) {
              setStartOverDisabled(true);
          }
      }

	}, [irContext]);


	useEffect(() => {
    const contextUpdate = () => {
      forceUpdate();
    };

    Emitter.addListener('choicesUpdated', contextUpdate);
    Emitter.addListener('questionnaireCleared', contextUpdate);
    return () => {
      Emitter.removeListener('choicesUpdated', contextUpdate);
      Emitter.removeListener('questionnaireCleared', contextUpdate);
    }
  }, []);

	return (
		<div className="irjs__footer">
			<div className="irjs__btn-group">
				<button
					type="button"
					className={ clsx(
							'irjs__btn',
							'irjs__btn-footer', 
							'irjs__btn-footer--prev',
							irContext.context.getCustomStyling('basicButton')
					) }
					onClick={ () => {
						irContext.setActiveIndex(irContext.activeIndex !== 0 ? irContext.activeIndex - 1 : 0);
						irContext.analytics.track('guidedrec questionnaire back question', {
							from_question: irContext.activeIndex,
							to_question: (irContext.activeIndex !== 0 ? irContext.activeIndex - 1 : 0),
						});
					} }
					disabled={ !disableBack() }
				>
					{irContext.lang.getSlugTranslation({slug:'questionnaire.backbtn'})}
				</button>
				<button
					type="button"
					className={ clsx(
						'irjs__btn',
						'irjs__btn-footer',
	          'irjs__btn-footer--next',
						irContext.activeIndex === questions.length - 1 && 'irjs__btn--solid',
						irContext.context.getCustomStyling('basicButton')
					) }
					onClick={ (e) => {

						e.preventDefault();

						let selection = {};
          if(currentQuestion.type === 'multiple_choice'){
            selection.choices = irContext._val.current[currentQuestion.id].reduce((arr, _value) => {
              let _tV = '';
              const _choice = currentQuestion.choices.find((_choice) => (_choice.id === _value));
              if(_choice && _choice.text) _tV = _choice.text;
              return [...new Set([...arr, _tV])];
            }, []);
          }

         if (irContext._val.current[currentQuestion.id] 
            && typeof irContext._val.current[currentQuestion.id] === 'object' 
            && (currentQuestion.type === 'price_range' || currentQuestion.type === 'ns_slider')) {
              const min = currentQuestion.default_range_min || irContext.questionnaire.default_price_min || 0;
              const max = currentQuestion.default_range_max || irContext.questionnaire.default_price_max || 500;

              if(currentQuestion.type === 'price_range'){
                selection = {
                  price_min: (irContext._val.current[currentQuestion.id] && irContext._val.current[currentQuestion.id].min >= 0 && (Number(irContext._val.current[currentQuestion.id].min) > min)) ? irContext._val.current[currentQuestion.id].min : null,
                  price_max: (irContext._val.current[currentQuestion.id] && irContext._val.current[currentQuestion.id].max && (Number(irContext._val.current[currentQuestion.id].max) < max)) ? irContext._val.current[currentQuestion.id].max : null,
                };
              }

              if(currentQuestion.type === 'ns_slider'){
                selection = {
                  min: (irContext._val.current[currentQuestion.id] && irContext._val.current[currentQuestion.id].min >= 0 && (Number(irContext._val.current[currentQuestion.id].min) > min)) ? irContext._val.current[currentQuestion.id].min : null,
                  max: (irContext._val.current[currentQuestion.id] && irContext._val.current[currentQuestion.id].max && (Number(irContext._val.current[currentQuestion.id].max) < max)) ? irContext._val.current[currentQuestion.id].max : null,
                };
              }
          }

						irContext.analytics.track(`gr selected ${currentQuestion.mixpanel_group_slug}`, {
							questionnaire_id: irContext.questionnaire.id,
							question_id: currentQuestion.id,
							question_number: currentQuestion.number,
							...selection
						});

						if (irContext.activeIndex < questions.length - 1) {
							irContext.setActiveIndex(irContext.activeIndex < questions.length - 1 ? irContext.activeIndex + 1 : 0);

							irContext.analytics.track('guidedrec questionnaire next question', {
								from_question: irContext.activeIndex,
								to_question: irContext.activeIndex < questions.length - 1 ? irContext.activeIndex + 1 : 0,
							});
						}
						if (irContext.activeIndex === questions.length - 1) {
							e.preventDefault();

							irContext.analytics.track('guidedrec questionnaire submitted', {});

							if (props.onSubmit && props.onSubmit.constructor === Function) props.onSubmit();
						}
					} }
					disabled={ (irContext.activeIndex < questions.length - 1 || irContext.activeIndex === 0 && irContext.questionnaire.product_category === 'set_by_question_choices') ? !disableNext() : false }
				>
					{ 
						(irContext.activeIndex < questions.length - 1 || currentQuestion.mixpanel_group_slug === 'product_category'  || (questions.length === 1)) ? irContext.lang.getSlugTranslation({slug:'questionnaire.nextbtn'})  : (irContext.context.param?.customText && irContext.context.param?.customText.getRecsBtn) || irContext.lang.getSlugTranslation({slug:'questionnaire.getrecsbtn'})
        	}
				</button>
			</div>

    	<div className={clsx('irjs__footer--bottom')}>
			{ questions.length && (
        	<> 
        	{irContext.context.param.show_numbers && (
	        	<div className="irjs__pg-indicator">
		        	<span> 
		        	{ `${irContext.lang.getSlugTranslation({slug:'questionnaire.questionindex'})}: ${irContext.activeIndex + 1} / ${questions.length}`} 
		        	</span> 
	        	</div>
        	)} 
        	<div className={clsx('irjs__alert')}>
        		<button 
        			type="button"
        			className="irjs__btn irjs__btn--sm irjs__btn--startover"
        			onClick = {(e) => {
                        e.preventDefault();

                        irContext.session.updateSessionData('posted_query', false);

                        irContext.setQuestions([...irContext.questionnaire.questions].sort((a, b) => a.number - b.number));
                        irContext.setActiveIndex(0);
                        irContext.clearQuestionValues();

                        setTimeout(() => {
                            const clearValuesEvent = new CustomEvent('contextSelectionsCleared', { detail: true });
                            document.dispatchEvent(clearValuesEvent);
                        });


                        irContext.analytics.track('guidedrec questionnaire start over', {
                            questionnaire_id: Number(irContext.questionnaire.id),
                            start_over_from: irContext.activeIndex,
                            start_over_question_id: irContext.questionnaire.questions[irContext.activeIndex].id,
                        });

                    }}
                    disabled={ startOverDisabled }
                >
                    { irContext.lang.getSlugTranslation({slug:'questionnaire.startoverbtn'})} 
              </button> 
          </div>
          </> 
        )} 
        <div className="irjs__logo irjs__logo--static irjs__logo--sm">
            {!irContext.context.param.preferabli_logo && <PoweredByLogo logoNumber={irContext.context.param.logo_option}/>}
             {irContext.context.param.preferabli_logo && <PreferabliLogo logoNumber={irContext.context.param.logo_option}/>}
       	</div> 
        </div>

		</div>
	);
};

Footer.propTypes = {};

export default Footer;
