import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { formattedRelativePath } from 'HELPERS/url'; 
import { isFullUrl } from 'HELPERS/validator';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';

import { ResultsContext } from './context';

const Placeholder = ({ copySize, ...props }) => {

	const _c = useContext(ResultsContext);

	const handleShowModal = (e) => {
		e.preventDefault();
		if (window[_c.context._appPrefix]) {
			window[_c.context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id: _c.context.param?.questionnaire_id,
				selectedQuestionIdx: props.type === 'error' || props.type === 'noresults' ? 1 : null,
			});
		}
	};

	const CopySize = `${copySize}`;

	return (
		<div className={ clsx('irjs__placeholder') }>
			<div className="irjs__container">
				<div className="irjs__row">
					<div className="irjs__logo irjs__logo--bottom-right">
						{!_c.context.param?.preferabli_logo && <PoweredByLogo logoNumber={_c.context.param?.logo_option}/>}
						{_c.context.param?.preferabli_logo && <PreferabliLogo logoNumber={_c.context.param?.logo_option}/>}
					</div>
					<div className="irjs__placeholder--inner">
						{props.heading && props.heading.length && (
							<div className={ clsx('irjs__placeholder--heading') }>
								<h1>{props.heading}</h1>
							</div>
						)}
						<div className={ clsx('irjs__placeholder--copy') }>
							{React.isValidElement(props.copy) && (<>{props.copy}</>)}
							<div className={ clsx('irjs__placeholder--buttons') }>
								<button
									type="button"
									className={ clsx(
										'irjs__btn',
										'irjs__btn--solid',
										'irjs__btn--pill',
										_c.context.getCustomStyling('basicButton')
									) }
									id="getInstantRec"
									onClick={ handleShowModal }
								>
									{props.instantRecButton.text}
								</button>

								{props.redirectButton && Object.keys(props.redirectButton).length > 0 && (
									<button
										type="button"
										className={ clsx(
											'irjs__btn',
											'irjs__btn--pill',
											'irjs__btn--outline',
											_c.context.getCustomStyling('basicButton')
										) }
										id="errorRedirectBtn"
										onClick={ (e) => {
											e.preventDefault();
											window.location.href = isFullUrl(props.redirectButton.url) ? props.redirectButton.url : `${window.location.origin}${formattedRelativePath(props.redirectButton.url)}`;
										} }
									>
										{props.redirectButton.text}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Placeholder.propTypes = {
	heading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.shape()]),
	copy: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape()]),
	showGoBackButton: PropTypes.bool,
	instantRecButton: PropTypes.shape(),
	redirectButton: PropTypes.shape(),
	type: PropTypes.string,
};

export default Placeholder;
