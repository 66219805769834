import React, {
	useContext, useEffect, useRef, useState, createRef
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import parse from 'html-react-parser';
import styled from 'styled-components';


import LoadingDots from 'GLOBAL/LoadingDots';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';
import MaterialIcon from 'GLOBAL/MaterialIcon';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import LocalSession from 'UTILS/LocalSession';
import { getResultsTotal, mergeResults } from 'UTILS/AppUtils';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';

import ResultsSection from './achoredresults';
import Sidebar from './sidebar';
import Placeholder from './placeholder';
import NoResultsPlaceholder from './NoResultsPlaceholder';
import ErrorPlaceholder from './errorplaceholder';

const NotiCloseButon = styled.a`
position:absolute;
top:0.3125rem;
right:0.3125rem;
transform-origin: center center;
transform: scale(1);
opacity:0.75;
transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);

&:hover{
  transform:scale(1.2);
  opacity:1;
}
`;

import { ResultsContext } from './context';

const Results = (props) => {

	const _c = useContext(ResultsContext);

	const [postObj, setPostObject] = useState(false);
	const [hasApiError, setApiError] = useState(false);
	const [onLoadMore, setOnLoadMore] = useState(false);
	const [allowScroll, setAllowScroll] = useState(false);

	const [activeType, setActiveSectionType] = useState();
	const [queryResults, setQueryResults] = useState(null);
  const [recsLoading, setRecsLoading] = useState(true);
  const [recsQueried, setRecsQueried] = useState(false);
	const [notiRefs, setNotiRefs] = useState([]);

	const prevResults = useRef(null);
	const currResult = useRef(null);
	const _prev_query_id = useRef(null);
	const _questionnaire = useRef(null);

	const resultActiveSectionIdx = useRef(0);
	const resultsContainerRef = useRef(null);

	const recQuery = useRef({
	  questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param.questionnaire_id,
	  sort_by: 'preference',
	  limit: 6,
	});

	const scrollToElement = (section) => {
		const element = document.getElementById(section);

		let block = 'center';
		if (window.outerHeight < element.scrollHeight) block = 'start';
		var isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
		if (isSmoothScrollSupported) {
			element.scrollIntoView({
				behavior: 'smooth',
				block,
			});
		} else {
			element.scrollIntoView(false);
		}
	};

	const handleShowModal = () => {
		if (window[_c.context._appPrefix]) {

			_c.analytics.track('guidedrec modal opened', {
				questionnaire_id: Number(_c.context.param?.questionnaire_id),
				page_url: window.location.href,
				page_pathname: window.location.pathname,
			});

			window[_c.context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id: _c.context.getQuestionnaireId() || _c.context.param?.questionnaire_id,
			});
		}
	};

	useEffect(() => {
		currResult.current = queryResults;
		prevResults.current = queryResults;

		if(queryResults?.notifications && queryResults?.notifications.length){
	        setNotiRefs((nItemRef) =>
	          queryResults.notifications.map((_, i) => nItemRef[i] || createRef())
	        );
	      }

	}, [queryResults]);

	const getQuestionnaireResults = (postObj) => _c.api.postQuestionnaire(postObj);
	const handleQuestinnaireResults = ({
	  questionnaireResults = null,
	  prevResults = null,
	}) => {
	  if (
	    typeof questionnaireResults === 'object'
	    && Object.keys(questionnaireResults).length
	  ) {
	    let results = questionnaireResults;

	    const type_order = ['red', 'white', 'rose', 'sparkling', 'fortified'];
	    results.types = results.types.sort(
	      (a, b) => type_order.indexOf(a.nonLatinType)
	        - type_order.indexOf(b.nonLatinType)
	    ); // initial sort

	    if (
	      Object.prototype.toString.call(prevResults) === '[object Object]'
	      && prevResults.types.length
	    ) {
	      results = [...prevResults.types].reduce((currentArr, prevArr) => {
	        const currentTypeArr = currentArr.types.find(
	          (cTypeArr) => cTypeArr.nonLatinType === prevArr.nonLatinType
	        );
	        if (currentTypeArr) {
	          const newData = [...currentTypeArr.results];
	          const prevData = [...prevArr.results];
	          currentTypeArr.results = prevData.concat(newData);
	        }
	        if (!currentTypeArr) {
	          currentArr.types = [...currentArr.types, ...[prevArr]];
	        }
	        return currentArr;
	      }, results);

	      results.types = results.types.sort(
	        (a, b) => type_order.indexOf(a.nonLatinType)
	          - type_order.indexOf(b.nonLatinType)
	      ); // secondary sort when adding type update

	      setQueryResults(results);
	    }

	    if (!prevResults) {
	      setQueryResults(results);
	      setRecsLoading(false);
	    }

	    setRecsQueried(true);

	    if (results && results.types && results.types.length && !prevResults) setActiveSectionType(questionnaireResults.types[0].nonLatinType);
	  }
	};



	const handleQuery = async (postQuery = null) => {
	  return await getQuestionnaireResults({ ...postQuery, include_wines: true});
	};


	useEffect(() => {

		const handleLoad = async () => {

			await _c.api.getQuestionnaire({
				id: Number(_c.context.param?.questionnaire_id),
			}).then((questionnaire) => {
				_questionnaire.current = questionnaire;
				_c.context.setQuestionnaire(questionnaire);
			});	

			let _query = {
        ...recQuery.current, 
        limit: 6,
        offset: 0,
      };

      if (_c.session.getSessionItem('question_responses') && _c.session.getSessionItem('filters')) {
        _query.question_responses = _c.session.getSessionItem('question_responses');
        _query.filters = _c.session.getSessionItem('filters');

        recQuery.current = _query;

        handleQuery(_query).then((_qResults) => {

        	_c.session.updateSessionData('posted_query', true);

          const type_order = ['reds', 'whites', 'roses', 'sparkling', 'fortified'];
          _qResults.types = _qResults.types
            .sort((a, b) => type_order.indexOf(a.nonLatinType) - type_order.indexOf(b.nonLatinType))
        
          handleQuestinnaireResults({
            questionnaireResults: _qResults,
          });

          let _types = {};

          if(_qResults.types.length){
            _qResults.types.reduce((obj, _type) => {
              obj[`${_type.nonLatinType}_has_more`] = _type.hasNextPage;
              obj[`${_type.nonLatinType}_number_of_results`] = _type.results.length;
              obj[`${_type.nonLatinType}_product_lookups`] = _type.results.reduce((arr, _result, idx) => {
                let _pName = '';
                const { product, lookups } = _result;
                if(lookups.length) _pName = lookups[0].product_name;
              return [...new Set([...arr, _pName])];
            }, []);

              obj[`${_type.nonLatinType}_products`] = _type.results.reduce((arr, _result, idx) => {
                const { product } = _result;
              return [...new Set([...arr, product.name])];
            }, []);

              return obj;
            }, _types);


            setActiveSectionType(_qResults.types[0].nonLatinType);
          }

           _c.analytics.track('guided rec results',{            
            guidedrec_results_total: _qResults.results_total,
            guidedrec_number_of_types: _qResults.types.length,
            guidedrec_product_lookups:  _qResults.types.reduce((arr, _type, idx) => {
              const _typeProductNames = _type.results.map((_result) => {
                let _pName = '';
                const { product, lookups } = _result;
                if(lookups.length) _pName = lookups[0].product_name;
                return [...new Set([...arr, _pName])];
              })

              return [...new Set([...arr, ..._typeProductNames])];
            }, []),
            guidedrec_products:  _qResults.types.reduce((arr, _type, idx) => {
              const _typeProductNames = _type.results.map((_result) => {
                const { product, lookups } = _result;
                return product.name;
              })

              return [...new Set([...arr, ..._typeProductNames])];
            }, []),
            ..._types,
          });

           const _choices =  _qResults.questionnaire_choices.reduce((obj, choice, idx) => {
              obj[`guidedrec_submitted_choice_${idx}_id`] = choice.id; 
              obj[`guidedrec_submitted_choice_${idx}_text`] = choice.text; 
              return obj;
            }, {});

           let _filters = {
            guidedrec_filter_pricemin: null,
            guidedrec_filter_pricemax: null,
           };

           if(_qResults.filters && _qResults.filters.length){
            _filters = _qResults.filters.reduce((obj, item) => {

              if(item.key === 'price_min'){
                obj.guidedrec_filter_pricemin = item.value;
              }
              if(item.key === 'price_max'){
                obj.guidedrec_filter_pricemax = item.value;
              }

              return obj;
            }, _filters);
           }	

            if(_qResults?.notifications && _qResults?.notifications.length){ 
              if(_qResults?.notifications.some((_n) => (_n.slug === 'waterfall_on_price_activated'))){ 
                _c.analytics.track('guided rec price waterfall',{                  
                  questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
                  questionnaire_query_id: _qResults.id,
                  ..._filters,
                });
              }
            }

            _c.analytics.track('guided rec submitted',{            
							questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
							questionnaire_query_id: _qResults.id,
							guidedrec_number_of_choices: _qResults.questionnaire_choices.map((_c) => (_c.id)).flat().length,
							guidedrec_choice_ids: _qResults.questionnaire_choices.map((_c) => (_c.id)).flat(),
							guidedrec_choices:  _qResults.questionnaire_choices.reduce((arr, choice, idx) => {
							  // return [...new Set([...arr, choice.text])];
							  return [...new Set([...arr, {id:choice.id, text: choice.text}])];
							}, []),
							guidedrec_filters: _query.filters,
							..._choices,
							..._filters,
						});

        })
        .catch((error) => {
          console.log('error', error);

          _c.analytics.track('guidedrec results not loaded', {            
						error: 'api',
						questionnaire_id: _c.session.getSessionItem('questionnaire_id') || _c.context.param?.questionnaireId,
						..._query,
					});

          setRecsLoading(false);
          setRecsQueried(true);
          setQueryResults(null);
        });
			} else {
				setRecsLoading(false);
				handleShowModal();
			}
		};

		handleLoad();
	}, []);

	const getMoreResults = (loadData) => {

		const { offset, type } = loadData;

		return new Promise(async (resolve, reject) => {

			const { max_results_per_type, max_results_per_page } = _questionnaire.current;
			let limit = null;

			if (!max_results_per_page 
				&& max_results_per_type 
				&& Number(offset) < max_results_per_type) {
				limit = 3;
				// limit = max_results_per_type - Number(offset);
			}

			if(max_results_per_page && limit > max_results_per_page){
				limit = max_results_per_page;
			}

			await getQuestionnaireResults({
				...recQuery.current,
				offset: Number(offset) + 1,
				...(limit !== null ? { limit } : {}),
				max_results_per_type,
				max_results_per_page,
				include_wines: true,
			}).then(
				(questionnaireResults) => {
					const filterType = questionnaireResults.types.filter(
						(qType) => qType.nonLatinType === type
					);

					questionnaireResults.types = filterType;

					if(filterType.length){
						handleQuestinnaireResults({
							questionnaireResults,
							prevResults: prevResults.current,
						});

						_c.analytics.track('guided rec show more results',{							
							questionnaire_id: _questionnaire.current.id,
							guidedrec_show_more_results_id: questionnaireResults.id,
							guidedrec_show_more_type: filterType[0].nonLatinType,
							guidedrec_show_more_results_total: filterType[0].results.length,
							show_more_type: type,
							guidedrec_product_lookups:  questionnaireResults.types.reduce((arr, _type, idx) => {
								const _typeProductNames = _type.results.map((_result) => {
								let _pName = '';
								const { product, lookups } = _result;
								if(lookups.length) _pName = lookups[0].product_name;
								return _pName;
								})
								return [...new Set([...arr, ..._typeProductNames])];
								}, []),
							guidedrec_products:  questionnaireResults.types.reduce((arr, _type, idx) => {
								const _typeProductNames = _type.results.map((_result) => {
								const { product, lookups } = _result;
								return product.name;
								})

								return [...new Set([...arr, ..._typeProductNames])];
							}, []),
						});
					}
					resolve();
				},
				() => {
					reject();
					_c.analytics.track('guidedrec more results not loaded', {						
						error: 'api',
						show_more_type: type,
			            questionnaire_id: Number(_c.context.param.questionnaire_id),
			            ...postObj,
		        });
				}
			);
		});

	};

	const handleScrollChange = (entry, section) => {
		const results = Object.keys(queryResults).length
			? queryResults
			: currResult.current;
		if (results.types && results.types.length > 1 && !onLoadMore && allowScroll) {
			const currentSectionIdx = results.types.findIndex((type) => type.nonLatinType === section);

			const nextSectionIdx = (entry.scrolldirection.vertical)
			? Math.min(currentSectionIdx + 1, results.types.length - 1)
			: Math.max(0, currentSectionIdx - 1);

			resultActiveSectionIdx.current = nextSectionIdx;
			setActiveSectionType(results.types[nextSectionIdx].nonLatinType);
		}
	};

	const handleSectionClick = (e) => {
		let sectionLinkData;
		if (e.target.nodeName === 'SPAN' && e.target.parentNode.nodeName === 'LI') {
			sectionLinkData = { ...e.target.parentNode.dataset };
		}
		if (e.target.nodeName === 'LI') {
			sectionLinkData = { ...e.target.dataset };
		}

		if (activeType !== sectionLinkData.section) {
			resultActiveSectionIdx.current = Number(sectionLinkData.sectionIdx);
			setActiveSectionType(sectionLinkData.section);
			scrollToElement(sectionLinkData.section);
		} else {
			scrollToElement(sectionLinkData.section);
		}
	};

	const enableScrollChange = () => {
		if (!allowScroll) setAllowScroll(true);
	};

	const defaultResultProps = {
		heading: _c.lang.getSlugTranslation({slug:'result.defaultheading'}),
		copy: <p>{_c.lang.getSlugTranslation({slug:'result.defaultsubheading'})}</p>,
		instantRecButton: {
			text: _c.lang.getSlugTranslation({slug:'result.defaultbtn'}),
		},
	};	

	const hasResultError = () => hasApiError;

	return (
		<>
			<div className={ clsx('irjs__results') }>
				<div
					className={ clsx(
						'irjs__results--heading',
						'irjs__results--heading-with-pbwr',
						_c.context.getCustomStyling('results.heading')
					) }>
					<h1>
						{(_c.context.param?.customText && _c.context.param?.customText.resultsHeading) || _c.lang.getSlugTranslation({slug:'result.resultsheading'})}
					</h1>
					{_c.context.param?.results_logo_placement === 'results' && (
						<div className="irjs__logo irjs__logo--top-right">
							{!_c.context.param?.preferabli_logo && <PoweredByLogo logoNumber={_c.context.param?.logo_option}/>}
							{_c.context.param?.preferabli_logo && <PreferabliLogo logoNumber={_c.context.param?.logo_option}/>}
						</div>
					)}
				</div>
				{recsLoading && (
					<LoadingDots 
						className={ clsx('fade', recsLoading && 'in', !recsLoading && 'd-none') } 
						text={(_c.context.param?.customText && _c.context.param?.customText.resultsLoader) || undefined}
						color={ ((_c.context.param?.customStyling && _c.context.param?.customStyling.loadingColor) ? { backgroundColor: _c.context.param?.customStyling.loadingColor } : {}) } />
				)}

				{(!recsLoading && !hasResultError() && queryResults?.notifications && queryResults.notifications.length > 0) ? queryResults.notifications.map((_n, _idx) => {
	              const {slug, title, text} = _n;
	              return (
	                <div className={clsx('irjs__notify')} ref={ notiRefs[_idx] } key={`${_n.slug}_${_idx}`}>
	                  <h3>{`${_c.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${slug}.title`})}`}</h3>
	                  <p>{`${_c.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${slug}.text`})}`}</p>
	                  <NotiCloseButon role="button" onClick={() => removeNoti(notiRefs[_idx].current)}><MaterialIcon icon="close" /></NotiCloseButon>
	                </div>
	              );
	            }) : null
	          }

				{_c.context.param?.resultsDisplayedAs === 'separated' && queryResults && queryResults.types && queryResults.types.length > 0 && !hasResultError() && !recsLoading && (
					<div className={ clsx( 
						'irjs__results--outer', 
						_c.context.param?.layout === 'column' && 'irjs__results--column'
					) }>
						<Sidebar 
							activeSection={ activeType } 
							sections={ queryResults.types } 
							onClick={ handleSectionClick } 
							parentRef={ resultsContainerRef } 
						/>
						<div className="irjs__results--content" ref={ resultsContainerRef }>
						{queryResults.types.map((grouping, idx) => (
							<ResultsSection
								key={ grouping.nonLatinType }
								id={ grouping.nonLatinType }
								loadMore={ getMoreResults }
								activeSection={ activeType }
								onScrollChange={ handleScrollChange }
								renderProductCards={ props.renderProductCards }
								onRenderComplete={ props.onRenderComplete }
								onComplete={ enableScrollChange }
								{ ...grouping }/>
						))}
						</div>
					</div>
				)}
				{queryResults && queryResults.types && !queryResults.types.length && !recsLoading && !hasApiError && <NoResultsPlaceholder />}
				{!queryResults && !recsLoading && !hasApiError && (<Placeholder { ...defaultResultProps } />)}
				{hasResultError() && !recsLoading && (<ErrorPlaceholder />)}
			</div>
		</>
	);
};

Results.propTypes = {
	onRenderComplete: PropTypes.func,
	renderProductCards: PropTypes.func,
};


export default React.memo(Results);
