import React, { useState,useEffect,useCallback,useContext,createRef,useRef,} from 'react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import InputMinMaxSlider from 'COMPONENTS/Input/InputMinMaxSlider';
import usePrevious from 'HOOKS/usePrevious';
import useMountedRef from 'HOOKS/useMountedRef';
import { localePrice } from 'HELPERS/price';
import { titleize } from 'HELPERS/text';
import platformDetect from 'HELPERS/platformDetect';
import { QuestionnaireContext } from './context';

const PriceRange = (props) => {

  const { isMobile } = platformDetect;

  const mounted = useMountedRef();
  const {questions, activeIndex, ...irContext} = useContext(QuestionnaireContext);
  const [inputRefs, setInputRefs] = useState([]);

  const [_temp, setTempValue] = useState(props.values);
  const [_mouseEvent, setMouseEvent] = useState({min:false, max:false});
  const [_inputVal, setInputValue] = useState(props.values);
  const [_sliderVal, setSliderValue] = useState(props.values);
  const [fUpdate, trigUpdate] = useState(false);

  const _alerted = useRef(false);
  
  const _values = useRef(props.values);
  const _prevValues = usePrevious(_values);

  useEffect(() => {
    if(mounted.current){
      setInputRefs((_item) => ['min', 'max'].map((_k) => inputRefs[_k] || createRef()))
    }
  }, [mounted]);

  useEffect(() => {

    var timer;
    let mouseEventFired = {};

    const handleChange = (e) => {
      const fieldType = e.target.id.slice(String('range_').length);
      let _v = e.target.value;

      const handleInputValidation = (_fieldValue) => {
        _fieldValue = Number(_fieldValue);
        if(!_alerted.current) {
        if(fieldType === 'min'){
            const _tempMax = (_temp['max'] || props.default_range_max || irContext.questionnaire[`default_price_max`] || 500);

            if(_fieldValue > _tempMax || (_fieldValue < props.default_range_min)) {
            
            if(_fieldValue > _tempMax) {
              confirm(irContext.lang.getSlugTranslation({slug: `guidedrec.questionnaire.min_price_alert`, canTitleize: false}));
              // if input max value === default_max and current max is less than default_max -> current min
              if((props.default_range_max === _temp['max']) && (_values.current[fieldType] >= props.default_range_min)) _fieldValue = _values.current[fieldType];
              // if input max value != default_min -> current field max value
              if((props.default_range_max !== _temp['max'])) _fieldValue = _temp['max'];
            }

            if(_fieldValue < props.default_range_min){
              // min can never be lower than default_max
              _fieldValue = props.default_range_min;
            }
            
          }
        }


        if(fieldType === 'max'){

          const _tempMin = _temp['min'] || props.default_range_min || irContext.questionnaire[`default_price_min`] || 0;

          if(_fieldValue < _tempMin || (_fieldValue > props.default_range_max)) {
          
            if(_fieldValue < _tempMin) {
              confirm(irContext.lang.getSlugTranslation({slug: `guidedrec.questionnaire.max_price_alert`, canTitleize: false}));
              // if input min value === default_min and current max is less than default_max -> current max
              if((props.default_range_min === _temp['min']) && (_values.current[fieldType] <= props.default_range_max)) _fieldValue = _values.current[fieldType];
              // if input min value != default_min -> current field min value
              if((props.default_range_min !== _temp['min'])) _fieldValue = _temp['min'];
            }

            if(_fieldValue > props.default_range_max){
              // max can never be greater than default_max
              _fieldValue = props.default_range_max;
            }
           
          }
        }

        setTempValue((_pV) => {
          const _obj = {..._pV};
          let v = _obj[fieldType];
          _obj[fieldType] = _fieldValue;
          return _obj;
        })

        _alerted.current = true;
        _values.current[fieldType] = _fieldValue;

        }


      }

      if(String(_v).length) {
        handleInputValidation(_v);
      }
      trigUpdate((_) => (!_));

    }  
    
    if(inputRefs.length){
      Object.keys(inputRefs).forEach((key) => {
        const inputElement = inputRefs[key].current;
        if (inputElement) {

          inputElement.addEventListener('change', (e) => {
            if(isMobile){
              clearTimeout(timer);
              timer = setTimeout(() => {
                if(String(e.target.value).length) handleChange(e);
              }, 250);
            }
          }); // mobile

          inputElement.addEventListener('input', (e) => {
            if(!isMobile){
              clearTimeout(timer);
              timer = setTimeout(() => {
                if(String(e.target.value).length) handleChange(e);
              }, 250);
            }
          }); // web step / keyboard
          
          
        }
      });
    }

    return () => {
      Object.keys(inputRefs).forEach((key) => {
        const inputElement = inputRefs[key].current;
        timer = null;
        mouseEventFired[key] = false;
        if (inputElement) {
          inputElement.removeEventListener('input', () => {});
          inputElement.removeEventListener('change', () => {});
        }
      });
    };

  }, [inputRefs]);

  useEffect(() => {

    if(!isEqual(_prevValues?.current || {}, _sliderVal)) {
        _values.current = { ..._sliderVal };
        trigUpdate((_) => (!_));
    }

  }, [_sliderVal]);

  useEffect(() => {
    _alerted.current = false;
    if(props.onValueChange && props.onValueChange.constructor === Function) props.onValueChange(props.id, _values.current);

  }, [fUpdate]);

  return (
    <>      
      <InputMinMaxSlider
          minValue={ props.default_range_min || irContext.questionnaire.default_price_min || 0 }
          maxValue={ props.default_range_max || irContext.questionnaire.default_price_max || 500 }
          step={ props.default_range_increment }
          onChange={ (_v) => {
            setSliderValue({..._v})
            setTempValue({..._v})
          } }
          formattedLabel={ (x) => {
            if (x === (props.default_range_max || irContext.questionnaire.default_price_max || 500)) {
              return `${localePrice(x, irContext.questionnaire.default_currency, 0)}+`;
            }
            return `${localePrice(x, irContext.questionnaire.default_currency, 0)}`;
          } }
          labelCuttOff={ 100 }
          values={ {..._values.current} }
          rangeClass={{
            slide: irContext.context.getCustomStyling('questionnaire.rangeDrag') || '',
            track: irContext.context.getCustomStyling('questionnaire.rangeTrack') || '',
            active_track: irContext.context.getCustomStyling('questionnaire.rangeTrackActive') || ''
          }}
          allowLabelSwitch
      />
      
      <div className={clsx('irjs__range-inputs')}>
      { ['min', 'max'].map((_k, _i) => {

        let _fieldProps = {
            // max: props.default_range_max || irContext.questionnaire[`default_price_max`] || 500,
            // min: props.default_range_min || irContext.questionnaire[`default_price_min`] || 0,
            type: 'number',
            inputMode: 'decimal',
            id: `range_${_k}`,
            name: `range_${_k}`,
            label: `${irContext.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${_k}_price`, canTitleize: true})}`,
            step: Number(props.default_range_increment),
            value: _temp[_k],
            onChange: (e) => {
                setTempValue((_pValues) => {
                const _obj = {..._pValues};
                _obj[_k] = e.target.value;
                return _obj;
                });
            },
            onFocus: (e) => {
              e.target.select();
            },
            onMouseDown: (e) => {
              setMouseEvent((_m) => {
                const _obj = {..._m};
                _obj[_k] = true;
                return _obj;
              });
            },
            onBlur: (e) => {},
            onKeyUp: (e) => {},
        };

        return (
          <div
            className={ clsx(
              'irjs__input-group',
              (_temp[_k] !== null && String(_temp[_k]).length) && 'irjs__input-has-value',
            ) }
            key={ `${_k}_range_input` }
          >
            <input 
              ref={ inputRefs[_i] } 
              data-lpignore="true" 
              { ..._fieldProps } 
            />
            <label htmlFor={ `price_${_k}` }>
              <span>{ irContext.lang.getSlugTranslation({slug: `guidedrec.questionnaire.${_k}_price`, canTitleize: false}) }</span>
            </label>
          </div>
        );
        })}
      </div>
      <div className={clsx('irjs__range-reset')}>
        <button 
          type="button"
          title="Reset"
          className={clsx('irjs__btn irjs__btn--sm')}
          disabled={ Object.keys(_temp).every(key => _temp[key] === (props[`default_range_${key}`] || irContext.questionnaire[`default_price_${key}`])) }
          onClick={() => {
            const _reset = {
              min: props.default_range_min || irContext.questionnaire[`default_price_min`] || 0,
              max: props.default_range_max || irContext.questionnaire[`default_price_max`] || 500,
            };

            setSliderValue({..._reset});
            setTempValue({..._reset});
            _values.current = {..._reset};
            trigUpdate((_) => (!_));

          }}
        >
          { irContext.lang.getSlugTranslation({slug: `guidedrec.questionnaire.reset`, canTitleize: false}) }
        </button> 
      </div>
    </>
  )
};

PriceRange.propTypes = {};
export default React.memo(PriceRange);
