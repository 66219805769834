import Mixpanel from './Mixpanel';
import { underscore } from 'HELPERS/text';

export default class PreferabliAnalytics{
  constructor(){
    this.integration_id = null;
    this.mp = null;
  }

  setup({ integration_id, opt_out_tracking }){
    this.integration_id = integration_id;

    if(!opt_out_tracking) {
      this.mp = new Mixpanel();
      this.mp.identify();
      this.mp.setIntegrationGrouping(integration_id);

    }

  }

  track(event, params){
     const _defaults = {
      integration_id: `${this.integration_id}`,
      integration_hostname: `${window.location.hostname}`,
      integration_href: `${window.location.href}`,
     }

     if(this.mp) this.mp.track(`${underscore(event)}`, { ..._defaults, ...params });

  }
  
}