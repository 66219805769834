import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Modal from 'COMPONENTS/modal/modal';
import LoadingDots from 'GLOBAL/LoadingDots';
import withContext from 'HOC/withContext';
import withQuestionnaire from 'HOC/withQuestionnaire';

import Emitter from 'UTILS/Emitter';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import LocalSession from 'UTILS/LocalSession';

import { formattedRelativePath } from 'HELPERS/url'; 
import { isFullUrl } from 'HELPERS/validator';

import { QuestionnaireProvider } from './context';
import Question from './question';
import Footer from './footer';
import Header from './header';

const Quiz = (props) => {

        const {
        isLoading,
        hasError,
        handleValueChange,
        submitRec,
        isResultPage,
        irContext,
        ...questionnaireProps
        } = props;

        const [modalOpen, setModalOpen] = useState(false);
        const questionnaireMounted = useRef(false);

        useEffect(() => () => {
                if (questionnaireMounted.current === false) {
                    setModalOpen(false);
                    questionnaireMounted.current = undefined;
                    setTimeout(()=> {
                    const updateEvent = new CustomEvent('appUnmout_questionnaire', { detail: { type: 'questionnaire' }} );
                    document.dispatchEvent(updateEvent);
                    props.root?.unmount();
                    });
                }
            },
            [questionnaireMounted.current]
        );

        // runs once
        useEffect(() => {
            questionnaireMounted.current = true;
            if (!modalOpen) setModalOpen(true);

            if(isResultPage() && props.session.getSessionItem('posted_query')){
                if(questionnaireProps && Object.keys(questionnaireProps).includes('selected_question_idx') && questionnaireProps.selected_question_idx !== 0){
                    irContext.setActiveIndex(questionnaireProps.selected_question_idx);
                }
            }

            props.analytics.track('guidedrec modal opened', {
                questionnaire_id: Number(props.context.param?.questionnaire_id),
                page_url: window.location.href,
                page_pathname: window.location.pathname,
            });

        }, []);

        const closeModal = (e) => {
            if (e) e.preventDefault();
            if (modalOpen) setModalOpen(false);
            questionnaireMounted.current = false;

            props.analytics.track('guidedrec modal closed', {
                questionnaire_id: Number(props.context.param?.questionnaire_id),
                page_url: window.location.href,
                page_pathname: window.location.pathname,
            });
        };

        return ( <> 
        	{ questionnaireMounted.current && (
                <React.StrictMode> 
        		<Modal isOpen={modalOpen} handleClose={ (e) => { e.preventDefault(); } }>
        			<Modal.Content> 
        			 
    					<div className = {clsx('irjs__questionnaire--container', props.context.getCustomStyling('questionnaire.container'))} >
    					<>
    						{isLoading && 
                                <LoadingDots 
                                    className={ clsx('fade', isLoading && 'in', !isLoading && 'hidden')}
                                    color={ ((props.context.param?.customStyling && props.context.param?.customStyling.loadingColor) ? { backgroundColor: props.context.param?.customStyling.loadingColor } : {}) }
                                    /> 
                            }

                            {(!isLoading && hasError) && ( 
                                <div className="irjs__error">
                                <h1>{ props.lang.getSlugTranslation({slug:'guidedrec.reload.heading'}) } </h1> 
                                <h3>
                                    { props.lang.getSlugTranslation({slug:'guidedrec.reload.copy1'}) } 
                                    <br />
                                    { props.lang.getSlugTranslation({slug:'guidedrec.reload.copy2'}) } 
                                </h3> 
                                <div className="irjs__row">
                                    <button 
                                        type="button"
                                        className="irjs__btn irjs__btn--solid"
                                        style = {{ margin: '0 10px' }}
                                        onClick = {() => { 
                                            props.analytics.track('guidedrec modal reloaded', {});
                                        }} 
                                    >
                                    { props.lang.getSlugTranslation({slug:'questionnaire.errorreload'}) }
                                    </button> 
                                    <button 
                                        type="button"
                                        className="irjs__btn"
                                        style = {{ margin: '0 10px' }}
                                        onClick = {() => { closeModal(); }}
                                    >
                                    { props.lang.getSlugTranslation({slug:'questionnaire.errorclose'})} 
                                    </button> 
                                </div> 
                                </div>
                            )} 

    						{!isLoading &&
    						irContext.questionnaire &&
    						Object.keys(irContext.questionnaire).includes('questions') &&
    						irContext.questionnaire.questions.length && ( 
							<>
							<div className="irjs__form--container">
    							<Header onClose={() => { closeModal(); }}/>
                                <form>
                                    <Question
                                        values={ irContext._val.current[irContext.questions[irContext.activeIndex].id] }
                                        onChange={ handleValueChange }
                                        { ...irContext.questions[irContext.activeIndex] }
                                    />
                                    <Footer onSubmit={ () => {
                                        submitRec().then((e) => {
                                            closeModal();
                                            window.location.href = isFullUrl(props.context.param?.resultsRedirect) ? props.context.param?.resultsRedirect : `${window.location.origin}${formattedRelativePath(props.context.param?.resultsRedirect)}`;
                                        }).catch((e) => {
                                            props.analytics.track('guidedrec submission error', {});
                                            alert('Error with your guided recommendation submission. Please try again.')
                                        });
                                    }} />
                                </form>
                            </div> 
                            </>
                            )} 
                        </> 
                        </div>
                        
                        </Modal.Content>  
                   	</Modal>
                    </React.StrictMode>
                   	)} 
                   	</>
               	);
};

Quiz.propTypes = {
    selectedQuestionIdx: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}; 


export default withContext(QuestionnaireProvider, withQuestionnaire(Quiz));