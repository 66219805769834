import React, { useRef, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const RangeTrack = React.forwardRef(function RangeTrack(props, ref) {
  const handleMouseDown = (e) => {
    if (!e.target.classList.contains('irjs__rangefield--track')) return;

    const { left, width } = ref.current.getBoundingClientRect();
    const { clientX } = e.touches ? e.touches[0] : e;

    const position = {
      x: Math.min(Math.max(clientX - left, 0), width),
      y: 0,
    };
    props.onTrackMouseDown(e, position);
  };
  const handleTouchStart = (e) => {
    handleMouseDown(e);
    e.preventDefault();
  };

  const setTrack = () => ({
    width: `${(props.percentages.max - props.percentages.min) * 100}%`,
    left: `${props.percentages.min * 100}%`,
  });

  return (
    <div
      className={ clsx('irjs__rangefield--track', 'irjs__rangefield--track-bg', props.rangeClass.track) }
      onMouseDown={ handleMouseDown }
      onTouchStart={ handleTouchStart }
      ref={ ref }
    >
      <div style={ setTrack() } className={ clsx('irjs__rangefield--track', 'irjs__rangefield--track-active', props.rangeClass.active_track) } />
      {props.children.length && props.children}
    </div>
  );
});

RangeTrack.propTypes = {
  children: PropTypes.node,
  onTrackMouseDown: PropTypes.func,
  percentages: PropTypes.objectOf(PropTypes.number),
};

export default RangeTrack;
