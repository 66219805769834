// import $ from 'jquery';

import './scss/style.scss';

import GuidedRecApp from './guidedrec';
import { isHTML } from 'HELPERS/validator';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';

"use strict";
(function ($) {
if ($.fn.preferabliGuidedRec === undefined) {

    var _guidedrec,
        _isJQ = !!(window.jQuery);

    function PreferabliGuidedRec(element, options) {

        this._guidedrec;
        this._defaultOptions = {
            lang: 'en'
        };

        $.extend({}, this);
        this._name = 'preferabliGuidedRec';
        this.element = element;

         if (typeof options.integration_id === 'undefined') {
            throw 'Intergration ID (integration_id) is required to access Preferabli Guided Rec functionality.';
        }

        if (options.resultsPerPage && Number(options.resultsPerPage) > 6) {
            throw 'Results are limited to 6 per type per page.';
        }

        if (typeof options.resultsRedirect === 'undefined') {
            throw 'Please add in full url or relative path to results page for Guided Recommendations.';
        }

        if (typeof options.sidemenuStickyTop !== 'undefined') {
            let stickyTop = options.sidemenuStickyTop;
            if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
                stickyTop = convertCssUnit(stickyTop);
            }

            if (stickyTop < 0) {
                throw 'sidemenuStickyTop has to be a number greater than or equal to 0.';
            }
        }

        this.opts = $.extend({}, this._defaultOptions, Object.assign(options, {
            root: element,
        }));

        this.init();

    };


    PreferabliGuidedRec.prototype = {
        constructor: PreferabliGuidedRec,
        init: function() {
            this._guidedrec = new GuidedRecApp(this.opts);
            window.preferabliGuidedRec = this._guidedrec;
            window.wrInstantRec = this._guidedrec;
        },
        show: function(params = {}) {
            const {
                type = 'questionnaire',
                questionnaire_id = null,
                resultsRedirect = null,
                element = null,
                error = null,
                noResults = null,
                layout = 'row',
                customStyling = {},
                ...options
            } = params;

            const that = this;

            let _el;

            if(element){
                if(element instanceof jQuery && element instanceof Object) _el = element[0]; 
                if(element instanceof Element) _el = element; 
            } 

            if(!element){
               if(that.root instanceof jQuery && that.root instanceof Object) _el = that.root[0]; 
                if(that.root instanceof Element) _el = that.root; 
            }

            switch (type){
                    case 'questionnaire':
                        this._guidedrec.renderComponent({
                            ...that.opts,
                            type,
                            resultsRedirect,
                            questionnaire_id: questionnaire_id || this.opts.questionnaire_id,
                            element: _el,
                            lang: that.opts.lang || 'en',
                            customStyling: { ...that.opts.customStyling, ...customStyling },
                            ...options,
                        });
                        break;
                    case 'results':
                        // check
                        if (error && Object.keys(error).length){
                            if (error.text && !isHTML(error.text)){
                                if (!error.textSize){
                                    throw 'Please include textSize. TextSize is size of the text you want to display, ie: 36px | 36.';
                                }
                                if (error.textSize){
                                    if (typeof error.textSize === 'string' && !String(error.textSize).match(/^(\d*\.?\d+)(?:px|em)?$/)){
                                        throw 'Please include one of the following units: px, em with textSize or numeric, ie: 36px | 36';
                                    }
                                    if (typeof error.textSize === 'number' && !String(error.textSize).match(/^(\d*\.?\d+)/)) {
                                        throw 'TextSize has to be string or numeric value, ie: 36px | 36';
                                    }
                                }
                            }

                            if (error.timeout){
                                if (error.timeout > 10 && error.timeout <= 30){
                                    const secToMills = parseFloat(1000 * Number(error.timeout));
                                    Context.setDefaultTimeout(secToMills);
                                } else {
                                    throw 'Error Timeout parameter has to be greater than 20 and less than 60 seconds.';
                                }
                            }
                        }

                        if (noResults && Object.keys(noResults).length){
                            if (noResults.text && !isHTML(noResults.text)){
                                if (!noResults.textSize){
                                    throw 'Please include textSize. TextSize is size of the text you want to display, ie: 36px | 36.';
                                }
                                if (noResults.textSize){
                                    if (typeof noResults.textSize === 'string' && !String(noResults.textSize).match(/^(\d*\.?\d+)(?:px|em)?$/)){
                                        throw 'Please include one of the following units: px, em with textSize or numeric, ie: 36px | 36';
                                    }
                                    if (typeof noResults.textSize === 'number' && !String(noResults.textSize).match(/^(\d*\.?\d+)/)) {
                                        throw 'TextSize has to be string or numeric value, ie: 36px | 36';
                                    }
                                }
                            }
                        }

                        if (typeof options.onRenderComplete !== 'undefined' && !options.renderProductCards) {
                            throw 'onRenderComplete callback function requires renderProductCards callback.';
                        }

                        if (typeof options.sidemenuStickyTop !== 'undefined') {
                            let stickyTop = options.sidemenuStickyTop;
                            if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
                                stickyTop = convertCssUnit(stickyTop);
                            }

                            if (stickyTop < 0){
                                throw 'sidemenuStickyTop has to be a number greater than or equal to 0.';
                            }
                        }

                        this._guidedrec.renderComponent({
                            ...that.opts,
                            type,
                            element: _el,
                            questionnaire_id: questionnaire_id || this.opts.questionnaire_id,
                            lang: that.opts.lang || 'en',
                            layout,
                            error,
                            noResults,
                            customStyling: { ...that.opts.customStyling, ...customStyling },
                            ...options,
                        });
                        break;
                    default:
                }
        },
    };

    $.fn.preferabliGuidedRec = function(options) {

        if (typeof options === "string") {
            return this.each(() => {
                var jqEl = $(this);
                var instance = jqEl.data('preferabliGuidedRec');
                return (instance && instance[options]) ? instance[options].apply(instance, Array.prototype.slice.call(arguments, 1)) : this;
            });

        } else {
            return this.each(() => {

                var jqEl = $(this);
                options = $.extend(true, {}, options);

                if (_isJQ) {
                    jqEl.data('preferabliGuidedRec', new PreferabliGuidedRec(this, options));
                } else {
                    jqEl[0].preferabliGuidedRec = new PreferabliGuidedRec(this, options);
                }

                return this;
            });
        }
    };

    $.fn.wrInstantRec = $.fn.preferabliGuidedRec;
    $.fn.preferabliGR = $.fn.preferabliGuidedRec;

}
})(window.jQuery);