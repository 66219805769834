import React, { useEffect, useContext, useState, useRef, createRef, } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Results from './results';

import { ResultsProvider, ResultsContext } from './context';
import withContext from 'HOC/withContext';

const RecResults = (props) => {

	const _c = useContext(ResultsContext);
	const resultsMounted = useRef(true);

	useEffect(
		() => () => {
			if (!resultsMounted.current) {
				resultsMounted.current = false;
				setTimeout(()=> {
					const updateEvent = new CustomEvent('appUnmout_results', { detail: { type: 'results' }} );
	    			document.dispatchEvent(updateEvent);
					props.root?.unmount();
				});
			}
		},
		[resultsMounted.current]
	);

	return (
		<>
		<React.StrictMode>
			{resultsMounted.current && (
				<div
					className={ clsx(
						'irjs__results--container',
						_c.context.getCustomStyling('results.container'),
						_c.context.param?.devMode && 'irjs__hidden'
					) }
				>
					<Results onRenderComplete={ props.onRenderComplete } renderProductCards={ props.renderProductCards } />
				</div>
			)}
		</React.StrictMode>
		</>
	);
};

RecResults.propTypes = {};

export default withContext(ResultsProvider, RecResults);
