import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash/throttle';

import MaterialIcon from 'GLOBAL/MaterialIcon';
import { useScrollPosition } from 'HOOKS/useScrollPosition';
import PoweredByLogo from 'LOGO/PoweredBy';
import PreferabliLogo from 'LOGO/Preferabli';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import { titleize, underscore } from 'HELPERS/text';
import { convertCssUnit, stringHasUnit } from 'HELPERS/formatter';


import { ResultsContext } from './context';

const Sidebar = (props) => {

	const _c = useContext(ResultsContext);

	const [willStick, setWillStick] = useState(false);
	const [sidebarOpts, setSidebarOptions] = useState(0);
	const [sideContainer, setSideContainer] = useState();
	const [sideMenu, setSideMenu] = useState();
	const sidebarMenuRef = useRef(null);
	const [currentType, setCurrentType] = useState();

	const backToTop = (e) => {
		e.preventDefault();
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	};

	const [sidebarStyle, setSidebarStyle] = useState();

	const handleShowModal = (e) => {
		e.preventDefault();

		if (window[_c.context._appPrefix]) {
			window[_c.context._appPrefix].renderComponent({
				type: 'questionnaire',
				questionnaire_id: _c.context.getQuestionnaireId() || _c.context.param?.questionnaire_id,
				selected_question_idx: _c.context.param?.selected_question_idx || 0,
			});
		}
	};

	useScrollPosition(
		({ currPos }) => {
			if (!_c.context.param?.sidemenuStatic
				&& Number(_c.context.param?.sidemenuStickyTop) >= 0
			) {
				const sideMenuRects = sideMenu.getBoundingClientRect();
				const minScroll = sidebarOpts.top - 50;
				const maxScroll = Math.abs(currPos.y) + sideMenuRects.height + sideMenuRects.height / 3;

				const isRowLayout = _c.context.param?.layout === 'column' 
					? true
					: maxScroll <= props.parentRef.current.scrollHeight;
				
				const isSticking = window.innerWidth > 568
				&& window.scrollY > 0
				&& window.scrollY >= minScroll
				&& isRowLayout;

				let stickyTop = _c.context.param?.sidemenuStickyTop;
				if (typeof stickyTop === 'string' && stringHasUnit(stickyTop)) {
					stickyTop = convertCssUnit(stickyTop);
				}

				if (sideContainer && _c.context.param?.layout === 'column') {
					sideContainer.style.height = isSticking
						? sideMenuRects.height
						: 'auto';
				}

				setSidebarStyle({
					...sidebarStyle,
					transform: isSticking ? `translate3d(0, ${stickyTop}px, 0)` : '',
					width: window.innerWidth > 568 ? sideContainer.clientWidth : '100%',
				});

				setWillStick(isSticking);
			}
		},
		[willStick, sidebarOpts], sideMenu);

	useEffect(() => {
		if (sideContainer && sideMenu) {
			setSidebarOptions({
				top: sideContainer.getBoundingClientRect().top,
				bottom: sideContainer.getBoundingClientRect().top + sideContainer.getBoundingClientRect().height,
				width: sideMenu.clientWidth,
				height: sideMenu.getBoundingClientRect().height,
			});
			if (!_c.context.param?.sidemenuStatic 
				&& Number(_c.context.param?.sidemenuStickyTop) >= 0 
				&& _c.context.param?.layout === 'column'
			) {
				window.addEventListener('resize', onResizeThrottle);
			}
		}

		return () => {
			window.removeEventListener('resize', onResizeThrottle);
		};
	}, [sideContainer, sideMenu]);

	const handleResize = (e) => {
		if (sideContainer && sideMenu && sideMenu.classList.contains('sticky')) {
			setSidebarStyle({
				...sidebarStyle,
				width: window.innerWidth > 568 ? sideContainer.clientWidth : '100%',
			});
		}
	};

	const onResizeThrottle = useCallback(throttle(handleResize, 1000), [
		sideMenu,
		sideContainer,
	]);

	useEffect(() => {
		setCurrentType(props.activeSection);
	}, [props.activeSection]);

	const getCustomTypeHeading = (type) => {
		if (
			_c.context.param?.customText 
			&& Object.keys(_c.context.param?.customText).includes('categories')
			&& Object.keys(_c.context.param?.customText.categories).length
		) {	
			type = type.toLowerCase();
			const _keys = Object.keys(_c.context.param?.customText.categories);
			if (_c.context.param?.typesPlural) type = type.slice(0, -1); // create singleton
			if(_keys.includes(underscore(type))){
				return `${titleize(type)}${(_c.context.param?.typesPlural) ? 's' : ''}`;
			}
		}
		return false;
	};

	return (
		<div ref={ setSideContainer } className={ clsx('irjs__results--sidebar') }>
			<div
				ref={ setSideMenu }
				className={ clsx('irjs__sidemenu--wrapper', willStick && 'sticky') }
				style={ sidebarStyle }
			>
				<div className="irjs__sidemenu">
					<h3>{_c.lang.getSlugTranslation({slug:'result.sidebarheading'})}</h3>
					<ul className="irjs__sidemenu--nav">
						{props.sections.map((resultType, idx) => {
							const { type, results, nonLatinType } = resultType;

							return (
								<li className={ clsx(
										'irjs__sidemenu--nav-item',
										currentType === nonLatinType && 'irjs__sidemenu--nav-active',
										_c.context.getCustomStyling('sidemenu.item'),
										currentType === nonLatinType && _c.context.getCustomStyling('sidemenu.itemActive')
									) }
									data-section={ nonLatinType }
									data-section-idx={ idx }
									onClick={ props.onClick }
									key={ nonLatinType }>
									<span>
										{getCustomTypeHeading(nonLatinType) || _c.lang.getSlugTranslation({slug:`type.${underscore(nonLatinType)}`}) }
									</span>
								</li>
							);
						})}
					</ul>
					<div className={ clsx('irjs__sidemenu--actions') }>
						<button
							type="button"
							className={ clsx(
								'irjs__btn',
								'irjs__btn--solid',
								_c.context.getCustomStyling(['sidemenu.modifySelectionsBtn','basicButton',])
							) }
							id="sidebarmodify"
							onClick={ handleShowModal }>
							{(_c.context.param?.customText && _c.context.param?.customText.modifySelectionsBtn) || _c.lang.getSlugTranslation({slug:'result.modifybtn'})}
						</button>
						<button
							type="button"
							className={ clsx(
								'irjs__btn',
								_c.context.getCustomStyling(['sidemenu.backToTopBtn','basicButton',])
							) }
							id="sidebacktotop"
							onClick={ backToTop }
							disabled={ !willStick }
						>
							<MaterialIcon icon="arrow_upward" />
							{' '}
							{_c.lang.getSlugTranslation({slug:'result.backtopbtn'})}
						</button>
					</div>
				</div>

				{_c.context.param?.results_logo_placement === 'sidebar' && (
					<div className="irjs__logo irjs__logo--static">
					{!_c.context.param?.preferabli_logo && <PoweredByLogo logoNumber={_c.context.param?.logo_option}/>}
					{_c.context.param?.preferabli_logo && <PreferabliLogo logoNumber={_c.context.param?.logo_option}/>}
					</div>
				)}
			</div>
		</div>
	);
};

Sidebar.propTypes = {
	onClick: PropTypes.func,
	sections: PropTypes.arrayOf(PropTypes.shape()),
	activeSection: PropTypes.string,
	parentRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
};

export default React.memo(Sidebar);
